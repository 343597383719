import type { Components } from "@mui/material/styles";

import { themeVariables } from "../../variables";

import { palette } from "./../../options/palette";

const SCREEN_BREAK = 1200;

export const MuiAppBar: Components["MuiAppBar"] = {
  defaultProps: {
    elevation: 0,
    position: "sticky",
  },
  styleOverrides: {
    colorPrimary: {
      backgroundColor: "transparent",
      color: palette.common.white,
    },
    root: {
      overflowX: "clip",
      // y overflow must be visible in order to display subheader indicators
      overflowY: "visible",
      // ensure z-index is set for all position variants
      zIndex: themeVariables.zIndex.appBar,
    },
    positionStatic: {
      // static positioning for smaller desktop screens
      position: "static",
      // sticky when there is room
      [`@media (min-height:${SCREEN_BREAK}px)`]: {
        left: "auto",
        right: 0,
        position: "sticky",
      },
    },
  },
};
