import type { Components } from "@mui/material/styles";

import { spacing } from "../../options/spacing";

/**
 * NOTE If updating padding in this file, check to see if the styling in
 *      libs/atoms/src/text-field/text-field.sx.ts needs to be updated as well.
 */
export const MuiOutlinedInput: Components["MuiOutlinedInput"] = {
  styleOverrides: {
    root: {
      "& fieldset": {
        borderRadius: 0,
      },

      "&.MuiInputBase-adornedStart": {
        paddingLeft: spacing(1.5),
      },
      "&.MuiInputBase-adornedEnd": {
        paddingRight: spacing(1.5),
      },
    },
    input: {
      padding: spacing(1.375, 1.5, 1.625),

      "&.MuiInputBase-inputAdornedStart": {
        paddingLeft: spacing(1),
      },
      "&.MuiInputBase-inputAdornedEnd": {
        paddingRight: spacing(1),
      },

      "&.MuiInputBase-inputSizeSmall": {
        padding: spacing(1, 1.75),

        "&.MuiInputBase-inputAdornedStart": {
          paddingLeft: spacing(1),
        },
        "&.MuiInputBase-inputAdornedEnd": {
          paddingRight: spacing(1),
        },
      },
    },
    inputAdornedStart: {
      flex: 1,
    },

    notchedOutline: {
      // even if shrink hasn't been set, we should shrink on focus
      ".Mui-focused > &, .MuiInputLabel-shrink ~ .MuiOutlinedInput-root > &": {
        "& legend": {
          // the span text is scaled to 75%, so we should only expand the background 75% (+ padding)
          maxWidth: "calc(75% + 24px)",
          "& > span": {
            maxWidth: "100%",
          },
        },
      },
    },
  },
};
