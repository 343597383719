import type { Components } from "@mui/material/styles";

import { spacing } from "../../options/spacing";

import { palette } from "./../../options/palette";

export const MuiListItemText: Components["MuiListItemText"] = {
  styleOverrides: {
    primary: {
      fontSize: 15,
      color: "black",
      fontWeight: 500,
    },
    secondary: {
      color: palette.divider,
      fontSize: spacing(2),
      fontWeight: 600,
    },
    // multiline applied when both primary and secondary are set
    multiline: {
      "& span": {
        marginBottom: 5,
      },
    },
  },
};
