import type { Components } from "@mui/material/styles";

import { spacing } from "../../options/spacing";

import { palette } from "./../../options/palette";

export const MuiSelect: Components["MuiSelect"] = {
  defaultProps: {
    variant: "outlined",
  },
  styleOverrides: {
    root: {
      alignItems: "center",
      backgroundColor: palette.common.white,
      display: "flex",
      borderRadius: 0,
      fontWeight: 500,
      gap: spacing(1.5),
      height: spacing(5),
      padding: 0,
      "&.MuiInputBase-sizeSmall": {
        height: spacing(4),
      },
    },
    select: {
      gap: spacing(1),
      height: spacing(5),
      minHeight: spacing(2),
      paddingTop: 0,
      paddingBottom: 0,
      display: "flex",
      alignItems: "center",
      "&:focus": {
        // null prevents the default theme value; just use the root value (allowing others to customize)
        backgroundColor: null,
        borderRadius: null,
      },
      "&.MuiInputBase-inputSizeSmall": {
        height: spacing(4),
      },
    },
  },
};
