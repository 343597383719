import { styled } from "@mui/material";

export const Splash = styled("div")(({ theme }) => ({
  alignItems: "center",
  backgroundColor: theme.palette.common.white,
  display: "flex",
  flexDirection: "column",
  height: "100%",
  paddingTop: "5%",
  position: "absolute",
  width: "100%",
  zIndex: 1500,
}));

export const Logo = styled("img")(({ theme }) => ({
  height: theme.spacing(18.5),
  marginBottom: theme.spacing(2.5),
  width: theme.spacing(50),
}));
