import { palette } from "./palette";
import { sizing } from "./spacing";

/**
 * These options override the MUI default theme
 * {@link https://material-ui.com/customization/default-theme/}
 */

export const themeOptions = {
  layout: {
    NAVBAR_HEIGHT: 7,
    CATALYST_WIDTH: 55,
    PROJECT_TOOLBAR_HEIGHT: 10,
    BRANCH_INDICATOR_HEIGHT: 1.5,
    BODY_MARGIN: 5,
    SCREEN_BREAK_1: 150,
    SCREEN_BREAK_2: 192,
    SCREEN_BREAK_3: 240,
    SCREEN_BREAK_4: 320,
  },

  palette,

  sizing,

  spacing(factor: number) {
    return `${sizing(factor)}px`;
  },

  transitions: {
    duration: {
      shortest: 200,
      short: 250,
      standard: 300,
      complex: 375,
    },
  },

  typography: {
    fontFamily: "'Barlow', sans-serif",
    fontWeightBold: 500,
  },

  zIndex: {
    appBar: 1100,
    catalyst: 1300,
    drawer: 1350,
    // help-scout beacon is 1500
    snackbar: 1600,
    modal: 1700,
    tooltip: 1800,
  },
} as const;

declare module "@mui/material/styles/createTheme" {
  interface Theme {
    layout: typeof themeOptions.layout;
    sizing: typeof themeOptions.sizing;
    zIndex: typeof themeOptions.zIndex;
  }
}
