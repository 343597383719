import debug from "debug";

import { ResourceClient } from "./resource-client";
import type { IQuery } from "./utils";

export abstract class LogClient<
  Model,
  Identifier = string,
  QueryParams extends Record<string, any> = IQuery,
> extends ResourceClient<Model, Identifier, QueryParams> {
  async create(model: Partial<Model>): Promise<Model> {
    debug(`citrine:resources:${this.constructor.name}`)("create", model);
    const result = await super.create(model);
    const id = this.getId(result);
    debug(`citrine:resources:${this.constructor.name}`)("created", id, result);
    return result;
  }

  async find(query?: QueryParams): Promise<Model[]> {
    debug(`citrine:resources:${this.constructor.name}`)("find", query);
    const result = await super.find(query);
    debug(`citrine:resources:${this.constructor.name}`)("found", query, result);
    return result;
  }

  async get(id: Identifier): Promise<Model> {
    debug(`citrine:resources:${this.constructor.name}`)("get", id);
    const result = await super.get(id);
    debug(`citrine:resources:${this.constructor.name}`)("got", id, result);
    return result;
  }

  async patch(args: [Identifier, Partial<Model>]): Promise<Model> {
    const [id] = args;
    debug(`citrine:resources:${this.constructor.name}`)("patch", id);
    const result = await super.patch(args);
    debug(`citrine:resources:${this.constructor.name}`)("patched", id, result);
    return result;
  }

  async update(model: Model): Promise<Model> {
    const id = this.getId(model);
    debug(`citrine:resources:${this.constructor.name}`)("update", id);
    const result = await super.update(model);
    debug(`citrine:resources:${this.constructor.name}`)("updated", id, result);
    return result;
  }
}

export default LogClient;
