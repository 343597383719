import type { Components } from "@mui/material/styles";

import { spacing } from "../../options/spacing";

import { palette } from "./../../options/palette";

export const MuiDialog: Components["MuiDialog"] = {
  styleOverrides: {
    paper: {
      border: `1px solid ${palette.grey[800]}`,
      minWidth: spacing(80),
    },
    root: {
      "& .MuiDialogTitle-root+.MuiDialogContent-root": {
        paddingTop: spacing(3),
      },
    },
  },
};
