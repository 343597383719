import type { Components } from "@mui/material/styles";

import { MuiAccordion } from "./accordion";
import { MuiAccordionSummary } from "./accordion-summary";
import { MuiAlertTitle } from "./alert-title";
import { MuiAppBar } from "./app-bar";
import { MuiButton } from "./button";
import { MuiCard } from "./card";
import { MuiCardActions } from "./card-actions";
import { MuiCardContent } from "./card-content";
import { MuiCardHeader } from "./card-header";
import { MuiCheckbox } from "./checkbox";
import { MuiChip } from "./chip";
import { MuiCssBaseline } from "./css-baseline";
import { MuiDialog } from "./dialog";
import { MuiDialogActions } from "./dialog-actions";
import { MuiDialogContent } from "./dialog-content";
import { MuiDialogTitle } from "./dialog-title";
import { MuiFormLabel } from "./form-label";
import { MuiInputBase } from "./input-base";
import { MuiInputLabel } from "./input-label";
import { MuiLink } from "./link";
import { MuiList } from "./list";
import { MuiListItem } from "./list-item";
import { MuiListItemText } from "./list-item-text";
import { MuiListSubheader } from "./list-subheader";
import { MuiMenuItem } from "./menu-item";
import { MuiOutlinedInput } from "./outlined-input";
import { MuiPaper } from "./paper";
import { MuiPopover } from "./popover";
import { MuiRadio } from "./radio";
import { MuiSelect } from "./select";
import { MuiSwitch } from "./switch";
import { MuiTab } from "./tab";
import { MuiTable } from "./table";
import { MuiTableCell } from "./table-cell";
import { MuiTableHead } from "./table-head";
import { MuiTableRow } from "./table-row";
import { MuiTabs } from "./tabs";
import { MuiTextField } from "./text-field";
import { MuiToolbar } from "./toolbar";
import { MuiTooltip } from "./tooltip";
import { MuiTypography } from "./typography";

export const components: Components = {
  MuiAccordion,
  MuiAccordionSummary,
  MuiAlertTitle,
  MuiAppBar,
  MuiButton,
  MuiCard,
  MuiCardActions,
  MuiCardContent,
  MuiCardHeader,
  MuiCheckbox,
  MuiChip,
  MuiCssBaseline,
  MuiDialog,
  MuiDialogActions,
  MuiDialogContent,
  MuiDialogTitle,
  MuiFormLabel,
  MuiInputBase,
  MuiInputLabel,
  MuiLink,
  MuiList,
  MuiListItem,
  MuiListItemText,
  MuiListSubheader,
  MuiMenuItem,
  MuiOutlinedInput,
  MuiPaper,
  MuiPopover,
  MuiRadio,
  MuiSelect,
  MuiSwitch,
  MuiTab,
  MuiTable,
  MuiTableCell,
  MuiTableHead,
  MuiTableRow,
  MuiTabs,
  MuiTextField,
  MuiToolbar,
  MuiTooltip,
  MuiTypography,
};
