import { RouteWatcher } from "@citrine/atoms/route-watcher";
import {
  SnackbarProvider,
  getPermissionSnackbarMessage,
  getPermissionSnackbarOptions,
} from "@citrine/atoms/snackbar";
import { isAccessError } from "@citrine/client";
import { queryClient, subscribe } from "@citrine/client/utils";
import { LangProvider } from "@citrine/lang";
import type { LangProps } from "@citrine/lang";
import type { ThemeProps } from "@citrine/theme";
import { ThemeProvider } from "@citrine/theme";
import { QueryClientProvider } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { OAuthCleaner } from "src/components/oauth-cleaner";

import { CatalystProvider } from "../catalyst";
import { TokenProvider } from "../token";

import "./error-logging";

function scrollToTop() {
  window.scrollTo(0, 0);
}

function ScrollToTopOnNavigation() {
  return <RouteWatcher onNavigation={scrollToTop} />;
}

function AccessErrorHandler() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    return subscribe("error", (error, query) => {
      if (isAccessError(error)) {
        navigate("/", { replace: true });
        enqueueSnackbar(
          getPermissionSnackbarMessage(),
          getPermissionSnackbarOptions({ key: `auth:${query.queryKey}` })
        );
      }
    });
  }, [enqueueSnackbar, navigate]);

  return null;
}

export function RootContext({
  children,
  messages,
  timeZone,
}: LangProps & ThemeProps & { timeZone?: string }) {
  return (
    <ThemeProvider>
      <QueryClientProvider client={queryClient}>
        <LangProvider messages={messages} timeZone={timeZone}>
          <SnackbarProvider>
            <AccessErrorHandler />
            <CatalystProvider>
              <ScrollToTopOnNavigation />
              <TokenProvider>
                {children}
                <OAuthCleaner />
              </TokenProvider>
            </CatalystProvider>
          </SnackbarProvider>
        </LangProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
}
