import type {
  ModelExpertModifiedConfigResponse,
  ModifiedConfigChange,
} from "@citrine/resources/catalyst";
import type { IGraphMLPredictorConfig } from "@citrine/resources/predictor";
import type { CatalystMode } from "@citrine/types/full-story";
import type { Dispatch, ReactNode, SetStateAction } from "react";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

export type { CatalystMode };

export interface CatalystPredictorState {
  config: IGraphMLPredictorConfig;
  hasWritePermissions: boolean;
  id?: string;
  isValidatingOrEvaluating: boolean;
  version?: string;
  updatePredictor: (
    modifiedConfig: ModelExpertModifiedConfigResponse
  ) => Promise<void>;
}

interface ObservableEvent {
  expanded: (newExpanded: boolean, prevExpanded: boolean) => void;
  mode: (newMode: CatalystMode, prevMode: CatalystMode) => void;
  predictor: (
    newPredictorState: CatalystPredictorState | undefined,
    prevPredictorState: CatalystPredictorState | undefined
  ) => void;
}

export interface CatalystState {
  changes?: ModifiedConfigChange[];
  expanded: boolean;
  mode: CatalystMode;
  modelExpertEnabled: boolean;
  predictor?: CatalystPredictorState;
  setChanges: Dispatch<SetStateAction<ModifiedConfigChange[] | undefined>>;
  setExpanded: Dispatch<SetStateAction<boolean>>;
  /** Tracks the last explicit mode selection that a user made (not an automatic mode change) */
  setMode: (mode: CatalystMode) => void;
  setModelExpertEnabled: Dispatch<SetStateAction<boolean>>;
  setPredictor: Dispatch<SetStateAction<CatalystPredictorState | undefined>>;
  subscribe: <Event extends keyof ObservableEvent>(
    event: Event,
    callback: ObservableEvent[Event]
  ) => () => void;
}

const catalystContext = createContext<CatalystState>({
  expanded: false,
  mode: "literature-expert",
  modelExpertEnabled: false,
  setChanges: () => {},
  setExpanded: () => {},
  setMode: () => {},
  setModelExpertEnabled: () => {},
  setPredictor: () => {},
  subscribe: () => () => {},
});

export const useCatalystContext = () => useContext(catalystContext);

/**
 * The `modelExpertEnabled` prop allows us to override the state in unit tests
 * and not have to rely on routing to test the Model Expert.
 *
 * `initialMode` is also exposed for testing purposes.
 **/
export function CatalystProvider({
  children,
  initialMode = "literature-expert",
  modelExpertEnabled: modelExpertEnabledProp = false,
}: {
  children: ReactNode;
  initialMode?: CatalystMode;
  modelExpertEnabled?: boolean;
}) {
  const [modelExpertEnabled, setModelExpertEnabled] = useState(
    modelExpertEnabledProp
  );

  const observers = useRef<
    Partial<{
      [K in keyof ObservableEvent]: Set<ObservableEvent[K]>;
    }>
  >({});
  const subscribe = useCallback<CatalystState["subscribe"]>(
    (event, callback) => {
      observers.current[event] ||= new Set<any>();
      observers.current[event]!.add(callback);
      return () => observers.current[event]!.delete(callback);
    },
    []
  );

  const [expanded, setExpanded] = useState(false);
  const expandedRef = useRef(expanded);
  expandedRef.current = expanded;
  useEffect(() => {
    const observersRef = observers;
    return () => {
      observersRef.current.expanded?.forEach((fn) => {
        fn(expandedRef.current, expanded);
      });
    };
  }, [expanded]);

  const [modeSelection, setMode] = useState<CatalystMode>(initialMode);

  const mode = modelExpertEnabled ? modeSelection : "literature-expert";

  const modeRef = useRef(mode);
  modeRef.current = mode;
  useEffect(() => {
    const observersRef = observers;
    return () => {
      observersRef.current.mode?.forEach((fn) => {
        fn(modeRef.current, mode);
      });
    };
  }, [mode]);

  const [predictor, setPredictor] = useState<CatalystPredictorState>();
  const predictorRef = useRef(predictor);
  predictorRef.current = predictor;
  useEffect(() => {
    const observersRef = observers;
    return () => {
      observersRef.current.predictor?.forEach((fn) => {
        fn(predictorRef.current, predictor);
      });
    };
  }, [predictor]);

  const [changes, setChanges] = useState<ModifiedConfigChange[]>();

  const contextValue = useMemo<CatalystState>(
    () => ({
      expanded,
      mode,
      modelExpertEnabled,
      changes,
      predictor,
      setExpanded,
      setMode,
      setModelExpertEnabled,
      setChanges,
      setPredictor,
      subscribe,
    }),
    [
      expanded,
      mode,
      modelExpertEnabled,
      changes,
      predictor,
      setExpanded,
      setPredictor,
      subscribe,
    ]
  );

  return (
    <catalystContext.Provider value={contextValue}>
      {children}
    </catalystContext.Provider>
  );
}
