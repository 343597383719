import type { Components } from "@mui/material/styles";

export const MuiTableHead: Components["MuiTableHead"] = {
  styleOverrides: {
    root: {
      fontSize: "0.875rem",
      fontWeight: 700,
    },
  },
};
