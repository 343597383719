import { isDevelopment } from "@citrine/configuration";

import {
  getStorageValue,
  setStorageValue,
  useStorageState,
} from "./use-storage-state";

const DEVMODE_KEY = "devmode";

export const useDevMode = () =>
  useStorageState<boolean | null>(DEVMODE_KEY, isDevelopment ? false : null);

declare global {
  interface Window {
    devmode: boolean | null;
  }
}

if (typeof window !== "undefined" && typeof window.devmode === "undefined") {
  /**
   * this property allows you to type `devmode = true` directly in the console
   */
  Object.defineProperty(window, "devmode", {
    get() {
      return getStorageValue(DEVMODE_KEY);
    },
    set(value?: boolean | null) {
      setStorageValue(DEVMODE_KEY, value == null ? value : Boolean(value));
    },
  });

  const KONAMI_CODE = [
    "ArrowUp",
    "ArrowUp",
    "ArrowDown",
    "ArrowDown",
    "ArrowLeft",
    "ArrowRight",
    "ArrowLeft",
    "ArrowRight",
    "KeyB",
    "KeyA",
  ] as const;

  let konamiIndex = 0;
  let konamiTimeout: number | null = null;
  window.document.body.addEventListener("keydown", (e) => {
    if (e.target === window.document.body) {
      if (e.code === KONAMI_CODE[konamiIndex]) {
        konamiIndex++;
        if (konamiIndex >= KONAMI_CODE.length) {
          window.devmode = !window.devmode;
          konamiIndex = 0;
        }
        if (konamiTimeout != null) {
          window.clearTimeout(konamiTimeout);
        }
        konamiTimeout = window.setTimeout(() => {
          konamiIndex = 0;
        }, 1000);
      }
    }
  });
}
