import type { Components } from "@mui/material/styles";

import { spacing } from "../../options/spacing";

export const MuiChip: Components["MuiChip"] = {
  styleOverrides: {
    sizeSmall: {
      fontSize: spacing(1.5),
    },
  },
};
