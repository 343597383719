import type { Components } from "@mui/material/styles";

import { spacing } from "../../options/spacing";

export const MuiCardContent: Components["MuiCardContent"] = {
  styleOverrides: {
    root: {
      padding: spacing(3),
      "& .MuiFormControl-root": {
        margin: spacing(1.5, 0),
        "&:first-of-type": {
          marginTop: 0,
        },
        "&:last-of-type": {
          marginBottom: 0,
        },
      },
    },
  },
};
