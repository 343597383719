import { isDevelopment } from "@citrine/configuration";
import type { MajorStatus } from "@citrine/types/status";
import type { Query, QueryKey, UseMutationResult } from "@tanstack/react-query";
import { QueryCache, QueryClient, QueryObserver } from "@tanstack/react-query";
import type { AxiosResponse } from "axios";

type ErrorCallback = (error: unknown, query: Query) => void;
const observers = {
  error: new Set<ErrorCallback>(),
};
export function subscribe(event: "error", fn: ErrorCallback) {
  observers[event].add(fn);
  return () => {
    observers[event].delete(fn);
  };
}

const queryCache = new QueryCache({
  onError(error, query: Query) {
    observers.error.forEach((fn) => {
      fn(error, query);
    });
  },
});
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
  queryCache,
});

export function login() {
  if (!isDevelopment) {
    const state = `${window.location.pathname}${window.location.search}${window.location.hash}`;
    window.location.replace(`/auth/login?${new URLSearchParams({ state })}`);
  }
}

export const LOGOUT_URI = "/auth/logout";
export function logout() {
  window.location.href = LOGOUT_URI;
}

type ErrorResponse = AxiosResponse<{ message?: string }>;
export class UnauthenticatedError extends Error {
  response?: ErrorResponse;
  constructor(response?: ErrorResponse) {
    super(response?.data?.message || response?.statusText);
    this.response = response;
    login();
  }
}
export class UnauthorizedError extends Error {
  response?: ErrorResponse;
  constructor(response?: ErrorResponse) {
    super(response?.data?.message || response?.statusText);
    this.response = response;
  }
}

export const STATIC_QUERY_OPTIONS = {
  gcTime: Infinity,
  refetchInterval: false,
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  retry: 3,
  staleTime: Infinity,
} as const;

/**
 * A utility method for waiting for a ReactQuery query to complete. Useful in
 * scenarios where you need to create/update a resource, which triggers cache
 * invalidation, and then wait for the refetching of the resources to complete.
 */
export function waitForQuery<T>(queryKey: QueryKey) {
  return new Promise<T>((resolve, reject) => {
    const unsubscribe = new QueryObserver<T>(queryClient, {
      queryKey,
    }).subscribe((result) => {
      if (result.isSuccess) {
        resolve(result.data);
      } else if (result.isError) {
        reject(result.error);
      }
      unsubscribe();
    });
  });
}

export function mutationStatusToProgressStatus(
  queryStatus: UseMutationResult["status"]
): MajorStatus | undefined {
  switch (queryStatus) {
    case "error":
      return "FAILED";
    case "pending":
      return "INPROGRESS";
    case "success":
      return "SUCCEEDED";
  }
}

export type IQuery = Partial<
  Record<string, string | number | boolean | string[]>
>;

export function normalizeQuery(query: Record<string, any> = {}): IQuery {
  return Object.entries(query)
    .sort()
    .reduce((acc, [key, value]) => {
      acc[key] = query && key in query ? query[key] : value;
      return acc;
    }, {} as IQuery);
}
