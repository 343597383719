import type {
  IconButtonProps as MuiIconButtonProps,
  TooltipProps,
} from "@mui/material";
import { IconButton as MuiIconButton, Tooltip } from "@mui/material";
import { forwardRef, useCallback, useState } from "react";

import * as sx from "./index.sx";

export interface IconButtonProps extends MuiIconButtonProps {
  tooltip?: TooltipProps["title"];
}

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  function IconButton({ onClick, tooltip, ...props }, ref) {
    const [handlingClick, setHandlingClick] = useState(false);

    const handleClick = useCallback(
      async (e: React.MouseEvent<HTMLButtonElement>) => {
        setHandlingClick(true);
        try {
          await onClick?.(e);
        } finally {
          setHandlingClick(false);
        }
      },
      [onClick, setHandlingClick]
    );

    return (
      <Tooltip title={tooltip ?? ""}>
        {/* Need to add a span here or else the Tooltip complains when the button is disabled */}
        <sx.Container>
          <MuiIconButton
            {...props}
            ref={ref}
            onClick={handleClick}
            disabled={props.disabled || handlingClick}
          />
        </sx.Container>
      </Tooltip>
    );
  }
);
