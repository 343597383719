import type { Components } from "@mui/material/styles";

import { spacing } from "../../options/spacing";

export const MuiInputBase: Components["MuiInputBase"] = {
  styleOverrides: {
    root: {
      lineHeight: "1",
      fontSize: "0.875rem",
    },
    input: {
      height: spacing(2),
    },
  },
};
