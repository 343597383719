import { forwardRef } from "react";

import * as sx from "./index.sx";

export interface ISpinnerProps {
  size?: number;
  className?: string;
  "data-testid"?: string;
  centered?: boolean;
}

export const Spinner = forwardRef<HTMLDivElement, ISpinnerProps>(
  ({ centered, ...props }, ref) => {
    return (
      <sx.SizedBox size={centered ? "100%" : `${props.size ?? 40}px`}>
        <sx.Spinner {...props} ref={ref} className={props.className} />
      </sx.SizedBox>
    );
  }
);
