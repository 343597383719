import type { SxProps, Theme } from "@mui/material";

export function style(sx: SxProps<Theme>): SxProps<Theme> {
  return sx;
}

export function merge(...sx: SxProps<Theme>[]): SxProps<Theme> {
  return sx
    .flatMap((sxi) => (Array.isArray(sxi) ? sxi : [sxi]))
    .filter(Boolean) as any;
}
