import type { Components } from "@mui/material/styles";

import { spacing } from "../../options/spacing";

import { palette } from "./../../options/palette";

export const MuiButton: Components["MuiButton"] = {
  defaultProps: {
    color: "primary",
    disableElevation: true,
    variant: "contained",
  },
  styleOverrides: {
    root: {
      // add border to all buttons so sizing does not change
      border: `1px solid ${palette.primary.main}`,
      borderRadius: 0,
      height: spacing(5),
      letterSpacing: "1px",
      padding: spacing(0, 3),
      textTransform: "uppercase",
      whiteSpace: "nowrap",

      "&:hover": {
        fontWeight: 800,
      },
      "&.Mui-disabled": {
        borderColor: `transparent`,
      },
    },
    outlinedPrimary: {
      borderColor: palette.primary.contrastText,
      color: palette.primary.contrastText,
      padding: spacing(0, 3),
      "&:hover": {
        background: "none",
        color: palette.primary.contrastText,
      },
    },
    sizeSmall: {
      height: "unset",
      padding: spacing(0, 1.5),
    },
    textPrimary: {
      borderColor: "transparent",
      color: palette.primary.contrastText,
      padding: spacing(0, 3),
      "&:hover": {
        color: palette.primary.main,
      },
    },
  },
};
