import type { Components } from "@mui/material/styles";

import { spacing } from "../../options/spacing";

import { palette } from "./../../options/palette";

export const MuiListItem: Components["MuiListItem"] = {
  styleOverrides: {
    root: {
      alignItems: "start",
      position: "relative",
      backgroundColor: palette.grey[50],
      "&:hover": {
        backgroundColor: palette.grey[50],
      },
    },
    button: {
      backgroundColor: palette.grey[50],
      "&:hover": {
        backgroundColor: palette.grey[50],
      },
    },
    divider: {
      borderBottom: `1px solid ${palette.grey[300]}`,
      "&:last-child": {
        borderBottom: "none",
      },
    },
    dense: {
      alignItems: "start",
      "& .MuiCheckbox-root": {
        padding: spacing(0.25, 1.25),
      },
      "& .MuiListItemText-multiline": {
        margin: 0,
        paddingTop: 0,
      },
      "& .MuiListItemText-root": {
        margin: spacing(0.25, 0),
      },
      "& .MuiListItem-root": {
        padding: 0,
      },
      "& .MuiButtonBase-root": {
        padding: spacing(0, 0.625, 0, 0),
      },
    },
    gutters: {
      "&.MuiListItem-dense": {
        paddingLeft: spacing(1.25),
        paddingRight: spacing(1.25),
      },
    },
  },
};
