import type { Components } from "@mui/material/styles";

import { themeOptions } from "../../options";

export const MuiAccordionSummary: Components["MuiAccordionSummary"] = {
  styleOverrides: {
    root: {
      minHeight: themeOptions.spacing(5),
      "&.Mui-expanded": {
        minHeight: "unset",
      },
    },
    content: {
      margin: 0,
      "&.Mui-expanded": {
        margin: 0,
      },
    },
  },
};
