import type { Components } from "@mui/material/styles";

import { palette } from "./../../options/palette";

export const MuiMenuItem: Components["MuiMenuItem"] = {
  styleOverrides: {
    root: {
      background: palette.common.white,
      fontSize: "0.875rem",
    },
  },
};
