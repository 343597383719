import { themeOptions } from "./options";

// taken from MUI
const DEFAULT_EASING_FUNCTION = "cubic-bezier(0.4, 0, 0.2, 1)";

const getTime = (value: number | string) =>
  typeof value === "number" ? `${value}ms` : value;

export const themeVariables = {
  ...themeOptions,

  spacing(...args: (string | number)[]) {
    return args
      .map((arg) => (typeof arg === "string" ? arg : themeOptions.spacing(arg)))
      .join(" ");
  },

  transitions: {
    ...themeOptions.transitions,

    create(
      props: string | string[],
      options: {
        duration?: number | string;
        easing?: string;
        delay?: number | string;
      } = {}
    ) {
      const {
        duration = themeOptions.transitions.duration.standard,
        easing = DEFAULT_EASING_FUNCTION,
        delay = 0,
      } = options;

      return (Array.isArray(props) ? props : [props])
        .map(
          (prop) => `${prop} ${getTime(duration)} ${easing} ${getTime(delay)}`
        )
        .join(",");
    },
  },
} as const;
