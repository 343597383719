import type { AxiosRequestConfig } from "axios";
import axios from "axios";

/**
 * Simple abstraction of axios.request
 * This abstraction allows us to replace it if necessary,
 * and also simplifies unwrapping the data object
 */
export async function request<T>(config: AxiosRequestConfig): Promise<T> {
  const response = await axios.request<T>(config);
  return response.data;
}

export abstract class RequestClient<Payload = unknown> {
  request<P = Payload>(config: AxiosRequestConfig): Promise<P> {
    return request(config);
  }
}

export default RequestClient;
