import { QueryClient } from "@citrine/client";
import type {
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
} from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import type { AxiosError } from "axios";
// import { v4 } from "uuid";

import { useConfiguration } from "../configuration";
import { getUserClient } from "../user";

import type { UserRequest } from "./user-request";

class UserRequestClient extends QueryClient<UserRequest> {
  clone: never;
  useClone: never;
  create: never;
  useCreate: never;
  delete: never;
  useDelete: never;
  update: never;
  useUpdate: never;
  upsert: never;
  useUpsert: never;

  constructor() {
    super({
      endpoint: "/api/v1/user-requests",
      getField: "data",
      findField: "data",
    });
  }

  useHasOutstandingRequests(options?: UseQueryOptions<UserRequest[]>) {
    const { is_admin } = getUserClient().useGetMe().data!;
    const enableSelfRegistration = useConfiguration("enableSelfRegistration");
    const requestsQuery = this.useFind(
      {},
      {
        ...options,
        enabled:
          // this request is only available to admins
          is_admin && enableSelfRegistration && options?.enabled !== false,
      }
    );
    return Boolean(requestsQuery.data?.length);
  }

  async reject(id: string): Promise<unknown> {
    try {
      const result = await this.request({
        method: "POST",
        url: `${this.getUri(id)}/reject`,
      });
      this.invalidateAfterMutation(id);
      return result;
    } catch {
      // do nothing
    }
  }

  useReject(
    options: UseMutationOptions<unknown, AxiosError, string> = {}
  ): UseMutationResult<unknown, AxiosError, string> {
    return useMutation({
      ...options,
      mutationFn: (id: string) => this.reject(id),
    });
  }

  async approve(id: string): Promise<unknown> {
    const result = await this.request({
      method: "POST",
      url: `${this.getUri(id)}/approve`,
    });
    this.invalidateAfterMutation(id);
    return result;
  }

  useApprove(options: UseMutationOptions<unknown, AxiosError, string> = {}) {
    return useMutation({ ...options, mutationFn: (id) => this.approve(id) });
  }
}

const userRequestClient = new UserRequestClient();
export const getUserRequestClient = () => userRequestClient;
