import { Suspense, lazy } from "react";
import type { IntlConfig } from "react-intl";
import { IntlProvider } from "react-intl";

const DEFAULT_LANG = "en";

const locale = (window.navigator.language || DEFAULT_LANG).split("-")[0];

const LazyLangProvider = lazy(() =>
  import(
    /* webpackChunkName: "languages/[request]" */ `../locales/${locale}.json`
  )
    // if the chosen locale cannot be found, use the default
    .catch(() => import(`../locales/${DEFAULT_LANG}.json`))
    .then(({ default: messages }) => ({
      default: (props: any) => (
        <IntlProvider {...props} messages={messages} locale={locale} />
      ),
    }))
);

export interface LangProps extends Pick<IntlConfig, "messages" | "timeZone"> {
  children: React.ReactNode;
}

export function LangProvider({ children, messages, timeZone }: LangProps) {
  return messages ? (
    <IntlProvider messages={messages} locale={locale} timeZone={timeZone}>
      {children}
    </IntlProvider>
  ) : (
    <Suspense fallback={null}>
      <LazyLangProvider timeZone={timeZone}>{children}</LazyLangProvider>
    </Suspense>
  );
}
