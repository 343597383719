import { getLookupMap } from "@citrine/utils/helpers";
import type { Complete } from "@citrine/utils/types";
import type { InfiniteData, QueryKey } from "@tanstack/react-query";
import type { AxiosRequestConfig } from "axios";
import { get } from "lodash";

import { RefreshClient } from "./refresh-client";
import { queryClient } from "./utils";
import type { IQuery } from "./utils";

export interface ResourceClientConfig<
  QueryParams extends Record<string, any> = IQuery,
> {
  endpoint: string;
  defaultQuery?: Complete<QueryParams>;
  findField?: string;
  getField?: string;
  idField?: string;
}

export const CONFIG_SYMBOL = Symbol("resource-config");
export const PAYLOAD_SYMBOL = Symbol("resource-payload");

export type WithConfig<T> = T & {
  [CONFIG_SYMBOL]: AxiosRequestConfig;
};

/**
 * Extend the RefreshClient with standard REST actions
 */
export abstract class ResourceClient<
  Model = unknown,
  Identifier = string,
  QueryParams extends Record<string, any> = IQuery,
> extends RefreshClient<Model> {
  protected readonly idField: string;
  protected readonly endpoint: string;
  protected readonly defaultQuery?: Complete<QueryParams>;
  protected readonly findField?: string;
  protected readonly getField?: string;

  constructor({
    endpoint,
    defaultQuery,
    findField,
    getField,
    idField = "id",
  }: ResourceClientConfig<QueryParams>) {
    super();
    this.endpoint = endpoint.replace(/\/+$/, ""); // strip trailing slash(es)
    this.defaultQuery = defaultQuery;
    this.getField = getField;
    this.findField = findField;
    this.idField = idField;
  }

  getId(model: Model): Identifier {
    return get(model, this.idField);
  }

  getLookupMap(models: Model[]): Partial<Record<string, Model>> {
    return getLookupMap(models, this.getId.bind(this) as any);
  }

  getUri(id?: Identifier): string {
    return `${this.endpoint}/${id}`;
  }

  /**
   * Clone a single Resource by id
   * Fetches the resource in question, then creates a copy with no id
   *
   * @param {string} id
   * @returns {Promise<Model>} the model requested
   */
  async clone(id: Identifier): Promise<Model> {
    const model = await this.get(id);
    return {
      ...model,
      [this.idField]: "",
    };
  }

  /**
   * Save a new model to the server
   *
   * @param {Model} model the model to save
   * @returns {Promise<Model>} the saved model (as returned from the server)
   */
  create(model: Partial<Model>): Promise<Model> {
    return this.asModel(this.request(this.createConfig(model)));
  }

  /**
   * Delete a model from the server
   *
   * @param {string} id the id of the model to delete
   * @returns {Promise<>}
   */
  delete(id: Identifier): Promise<unknown> {
    return this.request(this.deleteConfig(id));
  }

  /**
   * Query matching Resources
   *
   * @param {QueryParams} query should map directly to REST query params
   * @returns {Promise<Model[]>} the models requested
   */
  async find(query?: QueryParams): Promise<Model[]> {
    if (!this.idField) {
      throw new Error(
        `"idField" must be defined in constructor options in ${this.constructor.name} client`
      );
    }

    const config = this.findConfig(query);
    return Object.defineProperty(
      await this.asModels(this.request(config)),
      CONFIG_SYMBOL,
      {
        enumerable: false,
        writable: false,
        value: config,
      }
    );
  }

  /**
   * Fetch a single Resource by id
   *
   * @param {Identifier} id
   * @returns {Promise<Model>} the model requested
   */
  get(id: Identifier): Promise<Model> {
    return this.asModel(this.request(this.getConfig(id)));
  }

  /**
   * Patch a resource.
   *
   * We are using an array argument here because useMutation works with a single argument
   *
   * @param {[Identifier, Partial<Model>]} args the id of the model to be modified, and the patch to be applied
   * @returns {Promise<Model>} the modified model (as returned from the server)
   */
  patch(args: [Identifier, Partial<Model>]): Promise<Model> {
    return this.asModel(this.request(this.patchConfig(args)));
  }

  /**
   * Modify a complete resource
   *
   * @param {Model} model the model to be modified
   * @returns {Promise<Model>} the modified model (as returned from the server)
   */
  update(model: Model): Promise<Model> {
    return this.asModel(this.request(this.updateConfig(model)));
  }

  /**
   * Updates data in the infinite query cache for a model if the cache exists
   */
  updateInfiniteDataCache(
    cacheKey: QueryKey,
    updatePage: (page: WithConfig<Model[]>) => Model[]
  ) {
    // Check to see if there is InfiniteQuery data in the cache
    if (queryClient.getQueryData(cacheKey)) {
      // Taken from https://tanstack.com/query/v4/docs/react/guides/infinite-queries#what-if-i-want-to-manually-update-the-infinite-query
      queryClient.setQueryData<InfiniteData<Model[]>>(cacheKey, (data) => {
        const newPagesArray = data!.pages.map((page: WithConfig<Model[]>) => {
          const newPage = updatePage(page);
          Object.defineProperty(newPage, CONFIG_SYMBOL, {
            enumerable: false,
            writable: false,
            value: page[CONFIG_SYMBOL],
          });
          Object.defineProperty(newPage, PAYLOAD_SYMBOL, {
            enumerable: false,
            writable: false,
            value: [...newPage],
          });
          return newPage;
        });

        return {
          pages: newPagesArray,
          pageParams: data!.pageParams,
        };
      });
    }
  }

  /**
   * Modify or Create a complete resource
   *
   * @param {Model} model the model to be modified
   * @returns {Promise<Model>} the modified model (as returned from the server)
   */
  upsert(model: Model): Promise<Model> {
    return this.asModel(this.request(this.upsertConfig(model)));
  }

  /**
   * createConfig method is used to customize resource mutations
   *
   * This is used by this.create() to get the exact URL for creating a single model
   */
  protected createConfig(model: Partial<Model>): AxiosRequestConfig {
    return {
      method: "POST",
      url: this.endpoint,
      data: this.fromModel(model),
    };
  }

  /**
   * deleteConfig method is used to customize resource mutations
   *
   * This is used by this.delete() to get the exact URL for creating a single model
   */
  protected deleteConfig(id: Identifier): AxiosRequestConfig {
    return {
      method: "DELETE",
      url: this.getUri(id),
    };
  }

  /**
   * findConfig method is used to customize resource queries
   *
   * This is used by this.find() to get the exact URL for fetching an array of models
   */
  protected findConfig(query?: QueryParams): AxiosRequestConfig {
    return {
      method: "GET",
      url: this.endpoint,
      params: this.normalizeQuery(query),
    };
  }

  /**
   * getConfig method is used to customize resource fetches
   *
   * This is used by this.get() to find the exact URL for fetching a single model
   */
  protected getConfig(id?: Identifier): AxiosRequestConfig {
    return {
      method: "GET",
      url: this.getUri(id),
    };
  }

  /**
   * patchConfig method is used to customize resource mutations
   *
   * This is used by this.patch() to get the exact URL for mutating a complete model
   */
  protected patchConfig([id, patch]: [
    Identifier,
    Partial<Model>,
  ]): AxiosRequestConfig {
    return {
      method: "PATCH",
      url: this.getUri(id),
      data: this.fromModel(patch),
    };
  }

  /**
   * updateConfig method is used to customize resource mutations
   *
   * This is used by this.update() to get the exact URL for mutating a complete model
   */
  protected updateConfig(model: Model): AxiosRequestConfig {
    return {
      method: "PUT",
      url: this.getUri(this.getId(model)),
      data: this.fromModel(model),
    };
  }

  /**
   * upsertConfig method is used to customize resource mutations
   *
   * This is used by this.upsert() to get the exact URL for creating or mutating a model
   */
  protected upsertConfig(model: Model): AxiosRequestConfig {
    return this.getId(model)
      ? this.updateConfig(model)
      : this.createConfig(model);
  }

  /**
   * Map a server payload to the client model shape
   *
   * Override to handle processing, such as converting from snake_case to camelCase
   */
  protected toModel(payload: any): Model {
    return payload as Model;
  }

  protected fromModel(model: Partial<Model>): any {
    return model;
  }

  protected async asModel(res: Promise<any>): Promise<Model> {
    const payload = await res;

    const data = this.getField ? (payload as any)[this.getField] : payload;

    return this.toModel(data);
  }

  protected async asModels(res: Promise<any>): Promise<Model[]> {
    const payload = await res;

    const data = this.findField ? (payload as any)[this.findField] : payload;

    return Object.defineProperty(
      [].concat(data || []).map((datum) => this.toModel(datum)),
      PAYLOAD_SYMBOL,
      {
        enumerable: false,
        writable: false,
        value: payload,
      }
    );
  }

  protected normalizeQuery(query?: QueryParams): IQuery {
    return Object.entries(this.defaultQuery || query || {})
      .sort()
      .reduce((acc, [key, value]) => {
        acc[key] = query && key in query ? query[key] : value;
        return acc;
      }, {} as IQuery);
  }
}

export default ResourceClient;
