export const isBrowser = typeof window !== "undefined";

const isJest = process.env.NODE_ENV === "test";
export const isUnitTest = isJest;

const isPlaywright = isBrowser && Boolean((window as any).PLAYWRIGHT_ENV);
export const isIntegrationTest = isPlaywright;

export const isTest = isUnitTest || isIntegrationTest;

export const isDevelopment =
  isBrowser && !isTest && process.env.NODE_ENV === "development";
export const isProduction =
  isBrowser && !isTest && process.env.NODE_ENV === "production";
