import type { Components } from "@mui/material/styles";

import { spacing } from "../../options/spacing";

export const MuiCardHeader: Components["MuiCardHeader"] = {
  styleOverrides: {
    root: {
      padding: spacing(3, 3, 2),
    },
    title: {
      fontSize: spacing(2.5),
      fontWeight: 500,
    },
  },
};
