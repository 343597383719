import type { Components } from "@mui/material";

export const MuiList: Components["MuiList"] = {
  styleOverrides: {
    root: {
      alignItems: "start",
      // TODO: expand theme for multiple divider colors
      border: `1px solid rgba(0, 0, 0, 0.12)`,
      padding: 0,
    },
  },
};
