import type { Components } from "@mui/material/styles";

import { spacing } from "../../options/spacing";

export const MuiDialogContent: Components["MuiDialogContent"] = {
  styleOverrides: {
    root: {
      padding: spacing(3),
      "& .MuiFormControl-root": {
        margin: spacing(1.5, 0),
      },
    },
  },
};
