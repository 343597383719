import React, { createContext, useContext, useState } from "react";
import { useLocation } from "react-router-dom";

const tokenContext = createContext<string | null>(null);
export const useTokenContext = () => useContext(tokenContext);

export function TokenProvider({ children }: { children: React.ReactNode }) {
  const loc = useLocation();
  // since this hash value is provided by an external service,
  // we never have to worry about it changing throughout app lifetime;
  // and in fact, since the hash is cleaned, we couldn't if we wanted to
  const [value] = useState(() =>
    new URLSearchParams(loc.hash.slice(1)).get("id_token")
  );

  return (
    <tokenContext.Provider value={value}>{children}</tokenContext.Provider>
  );
}
