import type { Components } from "@mui/material/styles";

import { palette } from "./../../options/palette";

export const MuiCard: Components["MuiCard"] = {
  defaultProps: {
    elevation: 0,
  },
  styleOverrides: {
    root: {
      border: `1px solid ${palette.grey[300]}`,
    },
  },
};
