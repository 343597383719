import { isBrowser, isIntegrationTest } from "@citrine/configuration";
import type { AxiosRequestConfig, AxiosResponse } from "axios";
import axios from "axios";

import { RequestClient, request } from "./request-client";
import { UnauthenticatedError, UnauthorizedError } from "./utils";

export type AuthData =
  | { id_token: string }
  | { email: string; password: string };

interface RefreshData {
  refreshToken?: string;
}

export interface ITokenResponse {
  access_token: string;
}

async function makeTokenRequest(
  url: string,
  data?: RefreshData | (AuthData & { lifetime: number })
): Promise<ITokenResponse> {
  try {
    return await request<ITokenResponse>({
      method: "POST",
      url,
      data,
    });
  } catch (error) {
    if (error.response?.status >= 400 && error.response?.status < 500) {
      // couldn't refresh
      throw new UnauthenticatedError(error.response);
    } else {
      throw error;
    }
  }
}

export function generateTokens(authData: AuthData, lifetime: number = 300) {
  return makeTokenRequest("/api/v1/tokens/generate", {
    ...authData,
    lifetime,
  });
}

/**
 * If we rely solely on the auth service to determine that a token is expired,
 * a user who focuses the app after a 5+ minute delay may see many requests
 * attempt to refetch all simultaneously. All of these requests will fail due
 * to expired token, causing a second request for all of them.
 *
 * In order to avoid this duplicative effort, let's invalidate the token
 * manually just shy of the 5 minute mark. This way, we will pre-emptively
 * update the token before attempting the many refetch requests.
 */
let invalidationHandle: number | null = null;
let tokenIsInvalid: boolean = false;
function markTokenInvalid(ms: number) {
  if (invalidationHandle) {
    window.clearTimeout(invalidationHandle);
  }
  invalidationHandle = window.setTimeout(() => {
    tokenIsInvalid = true;
  }, ms);
}

let refreshResponse: Promise<ITokenResponse> | null = null;
export function refreshAccessToken(
  force?: boolean
): Promise<ITokenResponse | void> {
  // if running integration tests, don't override authentication
  if (isIntegrationTest) {
    return Promise.resolve();
  }

  // if a current refresh request is in-flight, don't make another
  if (force || !refreshResponse) {
    refreshResponse = makeTokenRequest("/api/v1/tokens/refresh").then(
      (data) => {
        // since we aren't in a browser (likely running download scripts in node)
        // we need to manually store the cookie
        if (!isBrowser) {
          axios.defaults.headers.common.Cookie = `access_token=${data.access_token}`;
        }
        // after refresh is complete; clear the cached response for next time
        tokenIsInvalid = false;
        refreshResponse = null;
        return data;
      }
    );

    if (isBrowser) {
      // mark the token invalid just shy of 5 minutes
      markTokenInvalid(295000);
    }
  }

  return refreshResponse;
}

export function isInvalidTokenResponse(
  response?: AxiosResponse<{ reason?: string }>
) {
  return response?.status === 401 && response?.data.reason === "invalid-token";
}

/**
 * If a request results in a 401 response, attempt to refresh the auth token
 * and retry the original request. Any other error throws
 */
export async function requestWithRefresh<T>(
  config: AxiosRequestConfig
): Promise<T> {
  if (tokenIsInvalid) {
    refreshAccessToken();
  }

  // if a refresh request is already in-flight, wait for it
  // the if-check isn't technically necessary, but we need it to avoid sync errors in jest tests
  if (refreshResponse) {
    await refreshResponse;
  }

  try {
    return await request<T>(config);
  } catch (error) {
    if (isInvalidTokenResponse(error.response)) {
      await refreshAccessToken();

      return request<T>(config);
    } else if (error.response?.status === 401) {
      throw new UnauthenticatedError(error.response);
    } else if (error.response?.status === 403) {
      throw new UnauthorizedError(error.response);
    } else {
      throw error;
    }
  }
}

/**
 * Extend the RequestClient with automatic auth refresh
 */
export abstract class RefreshClient<
  Payload = unknown,
> extends RequestClient<Payload> {
  request<P = Payload>(config: AxiosRequestConfig): Promise<P> {
    return requestWithRefresh(config);
  }
}

export default RefreshClient;
