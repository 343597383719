import { queryClient } from "@citrine/client/utils";
import { useQuery } from "@tanstack/react-query";
import { useCallback, useRef } from "react";

const QUERY_PREFIX = "query-cache";

export function getStorageValue<T = any>(key: string): T | null;
export function getStorageValue<T = any>(key: string, defaultValue: T): T;
export function getStorageValue<T = any>(
  key: string,
  defaultValue?: T
): T | null {
  return (
    (JSON.parse(window.localStorage.getItem(key) ?? "null") as T | null) ??
    defaultValue ??
    null
  );
}
export function setStorageValue<T = any>(key: string, val: T | null) {
  if (val == null) {
    window.localStorage.removeItem(key);
  } else {
    window.localStorage.setItem(key, JSON.stringify(val));
  }
  queryClient.invalidateQueries({ queryKey: [QUERY_PREFIX, key] });
}

/**
 * this is an alternative to react's useState that persists a value into
 * localStorage based on the provided key.
 *
 * This implementation uses react-query so that multiple mounts of the same key
 * will share the value and automatically re-render
 */
export function useStorageState<T = any>(
  key: string
): [T | null, React.Dispatch<React.SetStateAction<T | null>>];
export function useStorageState<T = any>(
  key: string,
  defaultValue: T
): [T, React.Dispatch<React.SetStateAction<T>>];
export function useStorageState<T = any>(
  key: string,
  defaultValue?: T
): [T | null, React.Dispatch<React.SetStateAction<T | null>>] {
  const value = useQuery({
    queryKey: [QUERY_PREFIX, key],
    queryFn: () => getStorageValue(key, defaultValue),
    initialData: getStorageValue(key, defaultValue),
    gcTime: Infinity,
    // refetch on refocus in case it was toggled in devtools or another tab
    staleTime: 0,
  });

  const valueRef = useRef<T | null>(value.data!);
  valueRef.current = value.data!;

  const setValue = useCallback(
    (val: React.SetStateAction<T | null>) => {
      const newValue =
        (val instanceof Function ? val(valueRef.current) : val) ??
        defaultValue ??
        null;
      setStorageValue(key, newValue);
      queryClient.setQueryData([QUERY_PREFIX, key], newValue);
    },
    [defaultValue, key]
  );

  return [value.data!, setValue];
}
