import type { Components } from "@mui/material/styles";

import { palette } from "../../options/palette";
import { spacing } from "../../options/spacing";

export const MuiTableCell: Components["MuiTableCell"] = {
  styleOverrides: {
    root: {
      borderStyle: "solid",
      borderWidth: "0 0 1px 0",
      borderColor: palette.grey[800],
      borderBottomColor: palette.grey[800],
      padding: spacing(1),
      "&.CellLayout-cell": {
        "&:first-of-type": {
          paddingLeft: spacing(1),
        },
      },
      "&.CellLayout-cell.MuiTableCell-head": {
        "&:first-of-type": {
          paddingLeft: spacing(1),
        },
      },
    },
    head: {
      textTransform: "uppercase",
      fontSize: "0.875rem",
    },
    footer: {
      fontSize: "0.875rem",
      color: palette.text.primary,
    },
  },
};
