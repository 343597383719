import type { Components } from "@mui/material/styles";

import { palette } from "./../../options/palette";

export const MuiPaper: Components["MuiPaper"] = {
  defaultProps: {
    square: true,
    elevation: 0,
  },
  styleOverrides: {
    outlined: {
      borderColor: palette.grey[300],
    },
  },
};
