import { IconButton } from "@citrine/atoms/icon-button";
import { style } from "@citrine/theme/style";
import { Close } from "@mui/icons-material";
import { styled } from "@mui/material";

export const CloseButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.common.white,
}));

export const CloseIcon = styled(Close)(({ theme }) => ({
  fontSize: theme.spacing(1.75),
}));

export const icon = style((theme) => ({
  marginInlineEnd: theme.spacing(1),
}));
