import CssBaseline from "@mui/material/CssBaseline";
import type { ClassNameMap } from "@mui/material/styles";
import {
  ThemeProvider as MuiThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
import clsx from "clsx";

import { theme } from "./material-ui/theme";

export { theme };
export { TAB_INDICATOR_SPACING } from "./material-ui/components/tab";

export type { CustomPaletteKey } from "./options/palette";

export interface ThemeProps {
  children: React.ReactNode;
}

export function ThemeProvider({ children }: ThemeProps) {
  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MuiThemeProvider>
    </StyledEngineProvider>
  );
}

export function mergeClasses<
  CNM1 extends ClassNameMap,
  CNM2 extends ClassNameMap,
>(classes1: CNM1 | undefined, classes2: CNM2 | undefined): CNM1 & CNM2 {
  const keys = [
    ...new Set([
      ...Object.keys(classes1 ?? {}),
      ...Object.keys(classes2 ?? {}),
    ]),
  ];

  return Object.fromEntries(
    keys.map((key) => [key, clsx(classes1?.[key], classes2?.[key])])
  ) as CNM1 & CNM2;
}
