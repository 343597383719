import { Spinner } from "@citrine/atoms/loading";
import { Fade } from "@mui/material";

import logo from "src/images/logo.splash.png";

import * as sx from "./index.sx";

export const SplashScreen = ({ show }: { show: boolean }) => (
  <Fade
    appear={false}
    in={show}
    style={{ zIndex: 9999999 }}
    timeout={500}
    unmountOnExit
  >
    <sx.Splash data-testid="splash">
      <sx.Logo src={logo} alt="logo" />
      <Spinner />
    </sx.Splash>
  </Fade>
);
