import { refreshAccessToken } from "@citrine/client";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import { RootContext } from "src/contexts/root";
import { Main } from "src/main";

// refresh on initial page load
if (process.env.NODE_ENV !== "test") {
  refreshAccessToken();
}

const contextFreePathname = window.location.pathname.replace(
  /^\/(cp2?|triton)(\/|$)/,
  () => "/"
);
window.history.replaceState(
  null,
  "",
  `${contextFreePathname}${window.location.search}${window.location.hash}`
);

// Render the App
window.document.addEventListener("DOMContentLoaded", () => {
  const root =
    window.document.getElementById("citrine-root") ?? window.document.body;
  ReactDOM.createRoot(root).render(
    <BrowserRouter>
      <RootContext>
        <Main />
      </RootContext>
    </BrowserRouter>
  );
});
