import type { Components } from "@mui/material/styles";

import { spacing } from "../../options/spacing";

export const MuiAlertTitle: Components["MuiAlertTitle"] = {
  styleOverrides: {
    root: {
      fontSize: spacing(2),
      lineHeight: 1.5,
      fontWeight: 500,
    },
  },
};
