import type { Components } from "@mui/material/styles";

import { spacing } from "../../options/spacing";

export const MuiInputLabel: Components["MuiInputLabel"] = {
  styleOverrides: {
    root: {
      lineHeight: "1.2em",
    },
    outlined: {
      transform: `translate(${spacing(1.5)}, ${spacing(1.375)}) scale(1)`,
      "&.MuiInputLabel-shrink": {
        transform: `translate(14px, -6px) scale(0.75)`,
      },
    },
  },
};
