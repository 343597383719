import type { Components } from "@mui/material/styles";

import { spacing } from "../../options/spacing";

import { palette } from "./../../options/palette";

export const MuiDialogTitle: Components["MuiDialogTitle"] = {
  styleOverrides: {
    root: {
      backgroundColor: palette.grey[300],
      fontSize: spacing(2.5),
      fontWeight: 500,
      lineHeight: 1.6,
      padding: spacing(0.5, 3),
      textTransform: "capitalize",
    },
  },
};
