import type { Components } from "@mui/material/styles";

import { spacing } from "../../options/spacing";

export const MuiDialogActions: Components["MuiDialogActions"] = {
  styleOverrides: {
    root: {
      padding: spacing(2, 3, 3),
    },
  },
};
