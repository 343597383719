import type { Components } from "@mui/material/styles";

import { palette } from "./../../options/palette";

export const MuiPopover: Components["MuiPopover"] = {
  styleOverrides: {
    paper: {
      backgroundColor: palette.common.white,
    },
  },
};
