import { refreshAccessToken, request, useDevMode } from "@citrine/client";
import { STATIC_QUERY_OPTIONS } from "@citrine/client/utils";
import { FEATURE_FLAG_DEFAULTS } from "@citrine/configuration";
import type { UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";

import type { IConfiguration } from "./configuration";
import { RUNTIME_CONFIG_KEY } from "./configuration";
import { useCustomConfig } from "./customization";

/**
 * This client does not require authentication and is read-only
 * We can create it directly without extending the resource functionality
 */
class ConfigurationClient {
  public async getRuntimeConfig(): Promise<IConfiguration> {
    const fetchRuntimeConfig = () =>
      request<IConfiguration>({
        url: `/api/v1/utils/runtime-config`,
      }).then((runtimeConfig) => ({
        ...FEATURE_FLAG_DEFAULTS,
        ...runtimeConfig,
      }));

    try {
      return await fetchRuntimeConfig();
    } catch (error) {
      // an unauthenticated runtime-config request returns a 404 instead of a 401
      if (error.response?.status === 404) {
        await refreshAccessToken();
        return fetchRuntimeConfig();
      }
      throw error;
    }
  }

  useGetRuntimeConfig(
    options: Omit<UseQueryOptions<IConfiguration>, "queryKey" | "queryFn"> = {}
  ): UseQueryResult<IConfiguration> {
    // since this is a static artifact, allow retries on error
    const runtimeConfigQuery = useQuery({
      ...STATIC_QUERY_OPTIONS,
      ...options,
      queryKey: RUNTIME_CONFIG_KEY,
      queryFn: () => this.getRuntimeConfig(),
    });

    const customConfig = useCustomConfig();

    const [devMode] = useDevMode();
    return useMemo(
      () =>
        devMode &&
        runtimeConfigQuery.isSuccess &&
        // only allow local customization if it is enabled remotely
        runtimeConfigQuery.data.enableFeatureCustomization
          ? {
              ...runtimeConfigQuery,
              data: {
                ...runtimeConfigQuery.data,
                ...Object.fromEntries(
                  Object.entries(customConfig ?? {})
                    .filter(([_, { enabled }]) => enabled)
                    .map(([key, { value }]) => [key, value])
                ),
              },
            }
          : runtimeConfigQuery,
      [customConfig, devMode, runtimeConfigQuery]
    );
  }
}

const configurationClient = new ConfigurationClient();
export const getConfigurationClient = () => configurationClient;
