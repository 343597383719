import type { Components } from "@mui/material/styles";

import { spacing } from "../../options/spacing";

import { palette } from "./../../options/palette";

export const MuiTextField: Components["MuiTextField"] = {
  defaultProps: {
    variant: "outlined",
  },
  styleOverrides: {
    root: {
      "--startAdornmentAllowance": "0px",
      "--endAdornmentAllowance": "0px",

      border: `none`,
      backgroundColor: palette.common.white,

      "& .MuiInputBase-root.MuiAutocomplete-inputRoot": {
        paddingBottom: 0,
        paddingTop: 0,

        "&:not(.MuiInputBase-adornedStart)": {
          paddingLeft: 0,
        },

        "& input.MuiAutocomplete-input": {
          padding: spacing(1.375, 1.5, 1.625),
        },
      },

      "&:has(.MuiInputBase-adornedStart)": {
        // 24px wide + 8px gap
        "--startAdornmentAllowance": "32px",
      },

      "&:has(.MuiInputBase-adornedEnd)": {
        // 24px wide + 8px gap
        "--endAdornmentAllowance": "32px",
      },

      "& .MuiFormLabel-root": {
        // 12px padding on each side
        maxWidth:
          "calc(100% - 24px - var(--startAdornmentAllowance) - var(--endAdornmentAllowance))",
        transform:
          "translate(calc(12px + var(--startAdornmentAllowance)), 11px) scale(1)",

        "&.MuiInputLabel-shrink, &.Mui-focused": {
          maxWidth: "100%",
          transform: "translate(14px, -6px) scale(0.75)",
        },
      },
    },
  },
};
