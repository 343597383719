import type { Components } from "@mui/material/styles";

import { spacing } from "../../options/spacing";

export const MuiToolbar: Components["MuiToolbar"] = {
  styleOverrides: {
    root: {
      // need to negate the Tabs bottom margin used for indicator offset
      "& .MuiTabs-root": {
        marginBottom: 0,
      },
    },
    regular: {
      height: spacing(7.5),
      padding: spacing(0, 5),
      "@media (min-width: 0px)": {
        minHeight: "unset",
        padding: spacing(0, 5),
      },
    },
  },
};
