import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useTokenContext } from "src/contexts/token";

export function OAuthCleaner() {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const token = useTokenContext();

  // ensure authorization token is wiped from URL hash
  useEffect(() => {
    if (token) {
      navigate(`${pathname}${search}`, { replace: true });
    }
  }, [navigate, pathname, search, token]);

  return null;
}
