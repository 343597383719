import type { Components } from "@mui/material/styles";

import { spacing } from "../../options/spacing";

import { palette } from "./../../options/palette";
import { TAB_INDICATOR_SPACING } from "./tab";

export const MuiTabs: Components["MuiTabs"] = {
  styleOverrides: {
    root: {
      marginBottom: spacing(-TAB_INDICATOR_SPACING),
      minHeight: spacing(5),
    },
    indicator: {
      backgroundColor: palette.primary.main,
      bottom: spacing(TAB_INDICATOR_SPACING),
      height: spacing(TAB_INDICATOR_SPACING),
      "&::after": {
        border: `${spacing(TAB_INDICATOR_SPACING)} solid transparent`,
        borderTop: ({ color }: { color?: string }) =>
          `${spacing(TAB_INDICATOR_SPACING)} solid ${
            color || palette.primary.main
          }`,
        content: '""',
        display: "block",
        position: "absolute",
        height: 0,
        width: 0,
        top: spacing(TAB_INDICATOR_SPACING),
        left: "50%",
        transform: `translateX(-50%)`,
      },
    },

    /**
     * the indicator defaults to left: 0 if no tab is selected
     * that leaves half of the indicator notch visible
     * by adding padding, left: 0 is moved beyond the extent of the left-most tab
     * by adding negative margin, we reset the tabs at the original starting point
     */
    scroller: {
      margin: spacing(0, -TAB_INDICATOR_SPACING),
      padding: spacing(0),
      zIndex: 1000,
    },
  },
};
