import { getUserClient } from "@citrine/resources/user";

import { AppLoader } from "./components/app-loader";

/**
 *
 * There are 3 states to handle when first mounting the app:
 *
 * 1. Loading
 *    We are waiting for the server to return the user's authentication status.
 *    In this case, we do nothing. Displaying a spinner to a user who is eventually
 *    resolved as unauthenticated and redirected to the login page is an awkward
 *    experience. The resolution is fast enough to be negligible.
 *
 * 2. Success - Authenticated
 *    The user has a currently active session.
 *    In this case, we use `me` to set the currently logged-in user in the main app.
 *    The AppLoader will now display the loading spinner while the rest of the
 *    application is loaded.
 *
 * 3. Error - Unauthenticated
 *    The user has never visited, manually logged out, or their session has expired.
 *    In this case, we send them to the login page.
 *
 */

export function Main() {
  const me = getUserClient().useGetMe();

  if (me.data) {
    return <AppLoader me={me.data} />;
  }

  if (me.isError) {
    getUserClient().login();
  }

  // initial loading spinner would go here if we want to add one
  return null;
}
