import type { Components } from "@mui/material/styles";

export const MuiAccordion: Components["MuiAccordion"] = {
  defaultProps: {
    square: true,
    elevation: 0,
  },
  styleOverrides: {
    root: {
      margin: 0,
      "&.Mui-expanded": {
        margin: "unset",
      },
      "&::before": {
        // Material UI adds a top border rendered as a ::before node that we don't want
        display: "none",
      },
    },
  },
};
