import type { Components } from "@mui/material/styles";

import { spacing } from "../../options/spacing";

export const MuiRadio: Components["MuiRadio"] = {
  defaultProps: {
    color: "primary",
  },
  styleOverrides: {
    root: {
      padding: spacing(1),
    },
  },
};
