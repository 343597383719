import type {
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import type { AxiosError, AxiosRequestConfig } from "axios";
import axios from "axios";
import { useMemo, useRef, useState } from "react";
import { v4 } from "uuid";

import { LogClient } from "./log-client";
import { useRevertableQuery } from "./use-revertable-query";
import type { IQuery } from "./utils";
import { queryClient } from "./utils";

export function getQueryCacheFromConfig(config: AxiosRequestConfig) {
  return [
    // url may be modified for this query
    config.url!,
    // params should have been sorted for reproducibility
    JSON.stringify(config.params),
    // some queries are done via POST body
    JSON.stringify(config.data),
  ];
}

export abstract class QueryClient<
  Model,
  Identifier = string,
  QueryParams extends Record<string, any> = IQuery,
> extends LogClient<Model, Identifier, QueryParams> {
  // ensure this key does not overlap with queryCachePrefix so that we can
  // clear queries but preserve models
  getModelCacheKey(id?: Identifier): QueryKey {
    return [axios.getUri(this.getConfig(id))];
  }

  getQueryCacheKey(query?: QueryParams): QueryKey {
    const config = this.findConfig(query);
    return [...this.getQueryCachePrefix(), ...getQueryCacheFromConfig(config)];
  }

  useClone(
    id?: Identifier,
    options?: Omit<UseQueryOptions<Model>, "queryKey" | "queryFn"> & {
      transform?: (model: Model) => Model;
    }
  ) {
    // we don't want clones to be re-used
    // so use a random key as part of component state to ensure it is
    // preserved throughout mount, but unique to this use
    const [cloneKey] = useState(v4);
    return useRevertableQuery({
      // most clones are used for editing; we probably don't want to do updates
      // cacheTime of 0 will remove this entry from cache immediately on unmount
      gcTime: 0,
      refetchInterval: false as const,
      refetchOnWindowFocus: false,
      ...options,
      queryKey: [...this.getModelCacheKey(id), "clones", cloneKey],
      queryFn: async () => {
        const model = await this.clone(id!);
        return options?.transform ? options?.transform(model) : model;
      },
      enabled: Boolean(id && (options?.enabled ?? true) && this.isEnabled()),
    });
  }

  async create(model: Partial<Model>): Promise<Model> {
    const result = await super.create(model);
    this.invalidateAfterMutation(this.getId(result));
    this.addModelToCache(result);
    return result;
  }

  /**
   * wrapper for react-query useMutation that returns a function to persist a new model
   * https://react-query.tanstack.com/reference/useMutation
   *
   * const { mutateAsync: create } = client.useCreate();
   * create({ id: "123", name: "abc" });
   *
   * @param {UseMutationOptions} options
   * @returns {UseMutationResult<Model>}
   */
  useCreate(
    options: Omit<
      UseMutationOptions<Model, AxiosError, Partial<Model>>,
      "mutationFn"
    > = {}
  ) {
    return useMutation<Model, AxiosError, Partial<Model>>({
      ...options,
      mutationFn: (model) => this.create(model),
    });
  }

  async delete(id: Identifier): Promise<unknown> {
    const result = await super.delete(id);
    this.invalidateAfterMutation(id);
    return result;
  }

  /**
   * wrapper for react-query useMutation that returns a function to delete an existing model
   * https://react-query.tanstack.com/reference/useMutation
   *
   * const { mutateAsync: delete } = client.useDelete();
   * delete("123");
   *
   * @param {UseMutationOptions} options
   */
  useDelete(
    options: Omit<
      UseMutationOptions<unknown, AxiosError, Identifier>,
      "mutationFn"
    > = {}
  ) {
    return useMutation<unknown, AxiosError, Identifier>({
      ...options,
      mutationFn: (id: Identifier) => this.delete(id),
    });
  }

  async find(query?: QueryParams): Promise<Model[]> {
    const result = await super.find(query);
    this.addModelsToCache(result);
    return result;
  }

  /**
   * wrapper for react-query useQuery that returns a list of models by search query
   * https://react-query.tanstack.com/reference/useQuery
   *
   * this uses the local `useRevertableQuery` wrapper hook, which adds an
   * onError handler to preserve data from a previous success
   *
   * const result = client.useFind({ module_type: "PREDICTOR" });
   * if (result.isSuccess) {
   *  result.data.map((model) => { console.log(model.id); });
   * }
   *
   * @param {QueryParams} query
   * @param {UseQueryOptions<Model[]>} options
   * @returns {UseQueryResult<Model[]>}
   */
  useFind(
    query?: QueryParams,
    options?: Omit<UseQueryOptions<Model[]>, "queryKey" | "queryFn">
  ) {
    return useRevertableQuery<Model[], AxiosError>({
      ...options,
      queryKey: this.getQueryCacheKey(query),
      queryFn: () => this.find(query),
      enabled: Boolean((options?.enabled ?? true) && this.isEnabled()),
    });
  }

  async get(id: Identifier): Promise<Model> {
    const result = await super.get(id);
    // manually set data in case it is fetched from a non-standard id
    this.addModelToCache(result, id);
    // if the result has a canonical id different from the request, add it to cache as well
    this.addModelToCache(result);
    return result;
  }

  /**
   * wrapper for react-query useQuery that returns a model by id
   * https://react-query.tanstack.com/reference/useQuery
   *
   * this uses the local `usePollingQuery` wrapper hook to provide smart
   * polling
   *
   * usePollingQuery uses the local `useRevertableQuery` wrapper hook, which
   * adds an onError handler to preserve data from a previous success
   *
   * const result = client.useGet("123");
   * if (result.isSuccess) {
   *  result.data.map((model) => { console.log(model.id); });
   * }
   *
   * @param {string} id
   * @param {UseQueryOptions<Model>} options
   * @returns {UseQueryResult<Model>}
   */
  useGet<Data = Model>(
    id?: Identifier,
    options?: Omit<
      UseQueryOptions<Model, AxiosError, Data>,
      "queryKey" | "queryFn"
    >
  ) {
    return useRevertableQuery<Model, AxiosError, Data>({
      ...options,
      queryKey: this.getModelCacheKey(id),
      queryFn: () => this.get(id!),
      enabled: Boolean(id && (options?.enabled ?? true) && this.isEnabled()),
    });
  }

  /**
   * wrapper for react-query useQuery that returns a map of models by search query
   * https://react-query.tanstack.com/reference/useQuery
   *
   * this uses the local `useRevertableQuery` wrapper hook, which adds an
   * onError handler to preserve data from a previous success
   *
   * this is just a convenience wrapper for getLookupMap after .useFind()
   *
   * const result = client.useMap();
   * if (result.isSuccess) {
   *   const model = result.data[modelId];
   * }
   *
   * @param {QueryParams} query
   * @param {UseQueryOptions<Model[]>} options
   * @returns {UseQueryResult<Partial<Record<string, Model>>>}
   */
  useMap(
    query?: QueryParams,
    options?: Omit<UseQueryOptions<Model[]>, "queryKey" | "queryFn">
  ): UseQueryResult<Partial<Record<string, Model>>> {
    const response = this.useFind(query, options);

    const responseRef = useRef(response);
    responseRef.current = response;

    return useMemo(
      () =>
        ({
          ...responseRef.current,
          status: response.status,
          data: response.data && this.getLookupMap(response.data),
        }) as any,
      [response.status, response.data]
    );
  }

  async patch(args: [Identifier, Partial<Model>]): Promise<Model> {
    const [id, patch] = args;
    const existingModel: Model | undefined = queryClient.getQueryData(
      this.getModelCacheKey(id)
    );
    if (existingModel) {
      this.addModelToCache(
        {
          ...existingModel,
          ...patch,
        },
        id
      );
    }
    const result = await super.patch(args);
    this.invalidateAfterMutation(this.getId(result));
    this.addModelToCache(result);
    return result;
  }

  /**
   * wrapper for react-query useMutation that returns a function to patch a model
   * https://react-query.tanstack.com/reference/useMutation
   *
   * const { mutateAsync: patch } = client.usePatch();
   * patch(["123", { name: "def" }]);
   *
   * @param {UseMutationOptions} options
   * @returns {UseMutationResult<Model>}
   */
  usePatch(
    options: Omit<
      UseMutationOptions<Model, AxiosError, [Identifier, Partial<Model>]>,
      "mutationFn"
    > = {}
  ) {
    return useMutation<Model, AxiosError, [Identifier, Partial<Model>]>({
      ...options,
      mutationFn: (args) => this.patch(args),
    });
  }

  async update(model: Model): Promise<Model> {
    this.addModelToCache(model);
    const result = await super.update(model);
    this.invalidateAfterMutation(this.getId(model));
    this.addModelToCache(result);
    return result;
  }

  /**
   * wrapper for react-query useMutation that returns a function to update a model
   * https://react-query.tanstack.com/reference/useMutation
   *
   * const { mutateAsync: update } = client.useUpdate();
   * update({ id: "123", name: "def" });
   *
   * @param {UseMutationOptions} options
   * @returns {UseMutationResult<Model>}
   */
  useUpdate(
    options: Omit<
      UseMutationOptions<Model, AxiosError, Model>,
      "mutationFn"
    > = {}
  ) {
    return useMutation<Model, AxiosError, Model>({
      ...options,
      mutationFn: (model) => this.update(model),
    });
  }

  // override the resource-client .upsert() for cache invalidations
  upsert(model: Model): Promise<Model> {
    return this.getId(model) ? this.update(model) : this.create(model);
  }

  /**
   * wrapper for react-query useMutation that returns a function to persist a model
   * https://react-query.tanstack.com/reference/useMutation
   *
   * const { mutateAsync: upsert } = client.useUpsert();
   * upsert({ id: "123", name: "abc" });
   *
   * @param {UseMutationOptions} options
   * @returns {UseMutationResult<Model>}
   */
  useUpsert(
    options: Omit<
      UseMutationOptions<Model, AxiosError, Model>,
      "mutationFn"
    > = {}
  ): UseMutationResult<Model, AxiosError, Model> {
    return useMutation<Model, AxiosError, Model>({
      ...options,
      mutationFn: (model) => this.upsert(model),
    });
  }

  invalidateAfterMutation(id?: Identifier) {
    if (id) {
      // remove all second-level model-specific queries
      queryClient.invalidateQueries({
        queryKey: this.getModelCacheKey(id),
        predicate: ({ queryKey }) =>
          Array.isArray(queryKey) && queryKey.length > 1,
      });
    }

    // invalidate all find queries
    queryClient.invalidateQueries({ queryKey: this.getQueryCachePrefix() });
  }

  protected addModelToCache(model: Model, id?: Identifier) {
    const modelId = id ?? this.getId(model);
    if (modelId) {
      queryClient.setQueryData(this.getModelCacheKey(modelId), model);
    }
  }

  protected addModelsToCache(models: Model[]) {
    models.forEach((model) => {
      this.addModelToCache(model);
    });
  }

  protected getQueryCachePrefix(): QueryKey {
    // use constructor name to differentiate between clients that share an endpoint
    // e.g., modules: design spaces, design workflows, performance workflows, predictors
    // use endpoint to differentiate between parents
    // e.g., predictors within a specific project
    return [this.constructor.name, this.endpoint];
  }

  protected isEnabled() {
    return true;
  }
}

export default QueryClient;
