import type { Components } from "@mui/material/styles";

import { spacing } from "../../options/spacing";

import { palette } from "./../../options/palette";

export const TAB_INDICATOR_SPACING = 0.75; // 6px; MUI spacing is based on 8px

export const MuiTab: Components["MuiTab"] = {
  styleOverrides: {
    root: {
      height: spacing(5),
      letterSpacing: "1px",
      margin: spacing(0, 2, TAB_INDICATOR_SPACING, 0),
      minWidth: spacing(20),
      minHeight: spacing(5),
      // allow angles of skewed progress indicators to be visible outside button bounds
      overflow: "visible",
      // padding: spacing(TAB_INDICATOR_SPACING),
      textTransform: "uppercase",
      // need to increase specificity above textColorInherit and progress "visited"
      "&.Mui-disabled[role=tab]": {
        opacity: 0.4,
        // the base Button disallows all pointer-events, but that prevents the not-allowed cursor
        pointerEvents: "unset",
        "&:hover": {
          cursor: "not-allowed",
        },
      },
      "&:last-child": {
        marginRight: 0,
      },
      // styling the `selected` variant directly will result in a generic .Mui-selected class
      // unfortunately, that will then apply to any selected component in the application
      "&$selected": {
        cursor: "default",
        fontWeight: 800,
        color: palette.grey[800],
      },
      "&::after": {
        content: '""',
        position: "absolute",
        bottom: 0,
        height: spacing(TAB_INDICATOR_SPACING),
        width: "100%",
        backgroundColor: palette.grey[500],
        display: "block",
      },
    },
    textColorInherit: {
      // this is used if "primary" or "secondary" is not specified
      color: palette.grey[500],
      opacity: 1.0,

      // for the progress tabs, if the user has already visited the step
      "&.visited": {
        color: palette.grey[700],
        "&::after": {
          backgroundColor: palette.grey[700],
        },
      },
    },
  },
};
