import type { Components } from "@mui/material/styles";

export const MuiTableRow: Components["MuiTableRow"] = {
  styleOverrides: {
    root: {
      // enable full-row anchor tags
      display: "table-row",
      textDecoration: "none",
    },
  },
};
