import type { Components } from "@mui/material/styles";

export const MuiTypography: Components["MuiTypography"] = {
  styleOverrides: {
    h1: {
      fontSize: "1.5rem",
      fontWeight: 500,
      lineHeight: 1.3333,
      textTransform: "uppercase",
      letterSpacing: "0.5px",
    },
    h2: {
      fontSize: "1.3125rem",
      fontWeight: 500,
      lineHeight: 1.3333,
      textTransform: "uppercase",
      letterSpacing: "0.5px",
    },
    h3: {
      fontSize: "1rem",
      fontWeight: 500,
      lineHeight: 1.5,
      textTransform: "uppercase",
      letterSpacing: "0.25px",
    },
    h4: {
      fontSize: "0.875rem",
      fontWeight: 600,
      lineHeight: 1.42857,
      textTransform: "uppercase",
      letterSpacing: "0.25px",
    },
    h5: {
      fontSize: "0.75rem",
      fontWeight: 600,
      lineHeight: 1.3333,
      textTransform: "uppercase",
      letterSpacing: "0.75px",
    },
    h6: {
      fontSize: "0.625rem",
      fontWeight: 600,
      lineHeight: 1.6,
      textTransform: "uppercase",
      letterSpacing: "1px",
    },
    subtitle1: {
      fontSize: "1rem",
      fontWeight: 500,
      lineHeight: 1.5,
      letterSpacing: "0.25px",
    },
    subtitle2: {
      fontSize: "0.875rem",
      fontWeight: 500,
      lineHeight: 1.4286,
      letterSpacing: "0.75px",
    },
    body1: {
      fontSize: "0.875rem",
      fontWeight: 500,
      lineHeight: 1.4286,
      letterSpacing: 0,
    },
    body2: {
      fontSize: "0.875rem",
      fontWeight: 300,
      lineHeight: 1.4286,
      letterSpacing: 0,
    },
    caption: {
      fontSize: "0.75rem",
      fontWeight: 400,
      lineHeight: 1.3333,
      letterSpacing: "0.25px",
    },
    overline: {
      fontSize: "0.75rem",
      fontWeight: 400,
      lineHeight: 1.3333,
      letterSpacing: "0.25px",
    },
  },
};
