import type { Components } from "@mui/material";

import { themeVariables } from "../../variables";

export const MuiCssBaseline: Components["MuiCssBaseline"] = {
  styleOverrides: {
    /*
      Some baselining necessary otherwise width + margin/padding calculation
      becaomes annoying. Look up what box-sizing does if you aren't familiar
    */
    html: {
      boxSizing: "border-box",
      // overscroll-behavior on html works in FF/Safari
      overscrollBehavior: "none",
    },

    body: {
      fontSize: "0.875rem",
      lineHeight: 1.43,
      letterSpacing: "0.01071em",
      margin: 0,
      minWidth: themeVariables.spacing(themeVariables.layout.SCREEN_BREAK_1),
      overflow: "auto",
      // overscroll-behavior on html works in Chromium
      overscrollBehavior: "none",
    },

    "*, *::before, *::after": {
      boxSizing: "inherit",
    },

    /* :target represents the element designated by the hash */
    ":target": {
      scrollMarginTop: themeVariables.spacing(
        themeVariables.layout.BODY_MARGIN
      ),

      [`@media (min-height: ${themeVariables.spacing(
        themeVariables.layout.SCREEN_BREAK_1
      )})`]: {
        scrollMarginTop: themeVariables.spacing(
          themeVariables.layout.NAVBAR_HEIGHT +
            themeVariables.layout.PROJECT_TOOLBAR_HEIGHT +
            themeVariables.layout.BODY_MARGIN
        ),
      },
    },

    "#citrine-root": {
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
      width: "100%",

      ".notistack-SnackbarContainer": {
        zIndex: themeVariables.zIndex.snackbar,
      },
    },

    "#beacon-container .BeaconFabButtonFrame": {
      bottom: themeVariables.spacing(themeVariables.layout.BODY_MARGIN / 2),
      right: themeVariables.spacing(themeVariables.layout.BODY_MARGIN / 2),
      transform: "scale(calc(2 / 3)) !important",
      transformOrigin: "bottom right",

      [`@media (min-width: ${themeVariables.spacing(
        themeVariables.layout.SCREEN_BREAK_2
      )})`]: {
        bottom: themeVariables.spacing(themeVariables.layout.BODY_MARGIN),
      },
      [`@media (min-width: ${themeVariables.spacing(
        themeVariables.layout.SCREEN_BREAK_3
      )})`]: {
        right: themeVariables.spacing(themeVariables.layout.BODY_MARGIN),
      },
      [`@media (min-width: ${themeVariables.spacing(
        themeVariables.layout.SCREEN_BREAK_4
      )})`]: {
        right: themeVariables.spacing(2 * themeVariables.layout.BODY_MARGIN),
      },
    },

    "#beacon-container .BeaconContainer": {
      bottom: themeVariables.spacing(2 * themeVariables.layout.BODY_MARGIN),
      height: "calc(100% - 100px)",
      right: themeVariables.spacing(themeVariables.layout.BODY_MARGIN / 2),

      [`@media (min-width: ${themeVariables.spacing(
        themeVariables.layout.SCREEN_BREAK_2
      )})`]: {
        bottom: themeVariables.spacing(2.5 * themeVariables.layout.BODY_MARGIN),
        height: `calc(100% - ${themeVariables.spacing(
          3 * themeVariables.layout.BODY_MARGIN
        )})`,
      },
      [`@media (min-width: ${themeVariables.spacing(
        themeVariables.layout.SCREEN_BREAK_3
      )})`]: {
        right: themeVariables.spacing(themeVariables.layout.BODY_MARGIN),
      },
      [`@media (min-width: ${themeVariables.spacing(
        themeVariables.layout.SCREEN_BREAK_4
      )})`]: {
        right: themeVariables.spacing(2 * themeVariables.layout.BODY_MARGIN),
      },
    },
  },
};
