import { Box, CircularProgress, styled } from "@mui/material";

export const Spinner = styled(CircularProgress)(({ theme }) => ({
  fontSize: theme.spacing(3.5),

  "& .MuiCircularProgress-svg": {
    color: theme.palette.grey[700],
  },
}));

interface SizedBoxProps {
  size: number | string;
}

export const SizedBox = styled(Box, {
  shouldForwardProp: (propName: keyof SizedBoxProps) => propName !== "size",
})<SizedBoxProps>(({ size }) => ({
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  overflow: "hidden",
  height: size,
  width: size,
}));
