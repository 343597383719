import type { Components } from "@mui/material/styles";

import { spacing } from "../../options/spacing";

export const MuiFormLabel: Components["MuiFormLabel"] = {
  styleOverrides: {
    root: {
      fontSize: spacing(1.75),
      // disable pointer-events because the z-index will cause them to be captured
      pointerEvents: "none",
      // the label is the first child, but it needs to be on top of any background
      zIndex: 1,
    },
  },
};
