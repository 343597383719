import { useEffect, useRef } from "react";
import { useLocation, useNavigationType } from "react-router-dom";

/**
 * RouteWatcher will trigger a callback when the route changes
 *
 * It accepts 2 callback options:
 *  - onNavigation, which only fires when clicking a link
 *  - onRouteChange, which fires on any change (including forward/back buttons)
 *
 * @param onNavigation {function} - only fires when clicking a link
 * @param onRouteChange {function} - fires on any change (including forward/back buttons)
 * @returns
 */
export function RouteWatcher({
  onNavigation,
  onRouteChange,
}: {
  onNavigation?: (pathname: string) => unknown;
  onRouteChange?: (pathname: string) => unknown;
}) {
  const { pathname } = useLocation();
  const action = useNavigationType();

  const onNavigationRef = useRef(onNavigation);
  onNavigationRef.current = onNavigation;

  const onRouteChangeRef = useRef(onRouteChange);
  onRouteChangeRef.current = onRouteChange;

  // do not fire on the first pass (component mounted)
  const enabledRef = useRef(false);
  useEffect(() => {
    if (enabledRef.current) {
      onRouteChangeRef.current?.(pathname);
      // ignore history.replace calls (which are most likely due to Redirect)
      if (action === "PUSH") {
        onNavigationRef.current?.(pathname);
      }
    }

    // enable watcher after component is mounted
    return () => {
      enabledRef.current = true;
    };
  }, [pathname, action]);
  return null;
}
